<template>
  <div>
    <div class="project-cart">
      <div :class="[{'content-box': !disableCard}, { 'no-card': disableCard }]">
        <div class="card-body">
          <div class="row align-items-center">
            <p class="w-100 text-center"><b>{{ projectId }}</b></p>
            <div class="col-12 d-flex justify-content-start align-items-center">
              <font-awesome-icon
                :icon="['fasdt', 'file-pen']"
                class="fa-2x egx-partner-color"  v-if="cartSettings.is_icon || (!cartSettings.is_icon && !cartSettings.img_url)"
              />
              <img
                :src="cartSettings.img_url"
                class="img-fluid w-25"
                v-if="!cartSettings.is_icon && cartSettings.img_url"
              />
              <p class="mb-0 ml-2">{{ measuresSize }} {{cartSettings.text}}</p>
            </div>
            <font-awesome-icon
              :icon="['fas', 'question-circle']" 
              class="fa-1x mr-3 ml-auto mt-2 egx-partner-color" 
              data-toggle="tooltip"
              :title="cartSettings.tooltip_text"
              ref="tooltip"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ProjectCart',
  props: {
    measuresSize: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      disableCard: false,
      projectId: this.$route.params.id
    };
  },
  computed: {
    cartSettings() {
      return this.$store.state.partners.currentPartner.settings.fulfill_sync_details.settings.project_measures_cart || {};
    },
  },
};
</script>
<style scoped>
.project-cart {
  max-width: 100%;
}

.project-cart .card-body {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.project-cart .fa-shopping-cart {
  margin-right: 0.25rem;
}
</style>
