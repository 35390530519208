<template>
  <Dialog :show="dialogVisible" @close="$event => closeDialog()">
    <div>
      <div>
        <div class="col mb-5 ">
          <span>{{message}}</span>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-3">
            <button class="btn btn-success" @click="$event => handleSave()">Confirm</button>
          </div>
          <div class="col-md-3">
            <button class="btn btn-danger" @click="$event => closeDialog()">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>
<script>
import Dialog from '../Dialog.vue';

export default {
  components: {
    Dialog,
  },

  props: {
    dialogVisible: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      required: true,
  },
},
  methods: {
    closeDialog() {
      this.$emit('close');
    },
    handleSave() {
      this.$emit('save');
    },
  },
};
</script>
