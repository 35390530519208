<template>
  <div class="popup-bubble">
    <button class="close-button" @click="closePopup">×</button>
    <div v-if="measureTypeName !== 'measureDetails'">
      <span><b>fulfillSyncDetails.pages?.projects_measure_list?.installation_details?.text</b></span>
      <div v-if="isLoading" class="loading-spinner">
        <font-awesome-icon :icon="['fa', 'circle-notch']" class="fa-spin" />
      </div>
      <div v-else-if="fields && fields.length" class="fields-container">
        <div v-for="(field, index) in fields" :key="index" class="field-row">
          <strong>{{ field.label }}:</strong> {{ fieldsValues[field.name] || 'N/A' }}
        </div>
      </div>
    </div>
    <div v-else>
      <span><b>fulfillSyncDetails.pages?.projects_measure_list?.measure_details?.text</b></span>
      <span>{{ measureDetails }}</span>
    </div>
    <div class="popup-arrow"></div>
  </div>
</template>

<script>
export default {
  name: 'PopupBubble',
  props: {
    measureTypeName: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    selectedRetrofit: {
      type: Object,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    measureDetails: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      fields: [],
      fieldsValues: {},
    };
  },
  created() {
    if (this.measureTypeName !== 'measureDetails') {
      this.fetchFields();
      this.selectedRetrofit.fields.forEach((field) => {
        this.fieldsValues[field.name] = field.value;
      });
    }
  },
  computed: {
    fulfillSyncDetails() {
      return this.settings.fulfill_sync_details;
    },
  },
  methods: {
    fetchFields() {
      this.isLoading = true;
      const params = {
        project_id: this.projectId,
        measure_type_name: this.measureTypeName,
      };

      this.$store
        .dispatch('fulfill_integrate/get_measures_type', params)
        .then((response) => {
          const retrofitFields = response.field_groups.flatMap((group) =>
            group.fields.map((field) => ({
              label: field.label,
              value: field.default_value || 'N/A',
              name: field.name,
            }))
          );
          this.fields = retrofitFields;

        })
        .catch((error) => {
          console.error('Error fetching fields:', error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    closePopup() {
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-bubble {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  background: #f7f7f7;
  padding: 8px 12px;
  border: 1px solid #a7a4a4;
  border-radius: 12px;
  font-size: 0.9rem;
  white-space: nowrap;
  z-index: 10;
  max-height: 300px;
}

.fields-container {
  max-height: 200px;
  overflow-y: auto;
}

.popup-arrow {
  position: absolute;
  top: 50%;
  left: -6px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #a7a4a4;
}

.loading-spinner {
  text-align: center;
  font-size: 1.5rem;
}

.field-row {
  margin-bottom: 4px;
  font-size: 0.85rem;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}
</style>
