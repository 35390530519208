<template>
  <div class="detail-section" :class="{ 'loading-size': isLoading }">
    <div class="m-2 text-center" v-if="isLoading">
      <font-awesome-icon :icon="['fa', 'circle-notch']" class="fa-spin m-auto spinner fa-6x egx-partner-color" />
    </div>
    <div :class="[{'content-box': !disableCard}, { 'no-card': disableCard }, 'mb-4']" v-else>
      <div class="card-wrapper">
        <div v-for="(groupedMeasure, index) in groupedMeasures" :key="groupedMeasure.id">
          <div class="measure-section col-12 py-4">
            <div class="row">
              <accordion-tab-list :id="`accordion-${index+1}`" :title="groupedMeasure.name"
                :accordion="`my-accordion--${index+1}`">
                <template #body>
                  <div class="incentives-list">
                    <div class="mr-2" v-for="(retrofit, index) in groupedMeasure.retrofits" :key="retrofit.pm_id">
                      <div class="retrofit-section pl-2">
                        <hr v-show="index !== 0" />
                        <div class="d-flex row">
                          <div class="d-grid pr-4 col-md-5 col-sm-12 pb-2 d-flex align-items-center">
                            <span class="measure-title btn" @click="selectMeasure(retrofit)">
                              {{ retrofit.name }}
                            </span>
                            <span class="measure-sub-title">
                              {{ retrofit.hierarchy_level_1 }}
                            </span>
                            <span class="measure-description pt-2" v-html="retrofit.program_specific_measure_details" />
                          </div>
                          <div class="d-flex align-items-center col-md-3">
                            <span class="mr-2 btn" @click="togglePopup(retrofit.pm_id, 'measureDetails')">
                              <font-awesome-icon :icon="['fa', 'info-circle']" class="fa-lg egx-partner-color" />
                            </span>
                            <span class="btn" @click="togglePopup(retrofit.pm_id)">
                              <font-awesome-icon :icon="['fa', 'list-alt']" class="fa-lg egx-partner-color" />
                            </span>
                            <PopupBubble
                              v-if="popupVisible[retrofit.pm_id]"
                              :selectedRetrofit="retrofit"
                              :retrofitId="retrofit.pm_id"
                              :measureTypeName="popupType[retrofit.pm_id] === 'measureDetails' ? 'measureDetails' : retrofit.type"
                              :project-id="projectId"
                              :onClose="() => togglePopup(retrofit.pm_id)"
                              :measureDetails="getMeasureDetails(retrofit.id)"
                            />
                          </div>
                          <div class="d-flex align-items-center pr-4 col-md-4 pb-2 text-center justify-content-between">
                            <div>
                              <small style="color: grey;">Quantity</small>
                              <input
                                type="number"
                                class="form-control"
                                placeholder="0"
                                v-model="retrofit.quantity"
                                style="width: 4rem; height: 2rem; margin: 0.2rem 0;"
                              />
                              <small style="color: grey;">unit(s)</small>
                            </div>
                            <div>
                              <span class="mr-4 btn" @click="openDialog(retrofit)">
                                <font-awesome-icon :icon="['fa', 'trash-alt']" class="fa-lg egx-partner-color" />
                              </span>
                              <span class="btn" @click="duplicateMeasure(retrofit)">
                                <font-awesome-icon :icon="['fa', 'copy']" class="fa-lg egx-partner-color" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </accordion-tab-list>
            </div>
          </div>
        </div>
      </div>
    </div>
    <step-button
      :steps-response="stepsResponse"
      @navigate="navigate"
      @save-data="handleStepSave"
      :data-save-required="true"
      class="mr-n4"
    />
    <measure-apply-modal
      :project-id="projectId"
      :selected-measure="selectedMeasure"
      :selected-retrofit="selectedRetrofit"
      :fulfillSyncDetails="fulfillSyncDetails"
      :is-duplicate="isDuplicate"
      @on-hide="resetSelectedMeasures"
      @on-save="updateMeasureList"
    />
    <ConfirmationModal
      :dialogVisible="dialogVisible"
      @close="closeDialog"
      @save="deleteMeasure(pmIdsToDelete)"
      :message="fulfillSyncDetails.messages.delete_measure_confirmation"
    />
  </div>
</template>

<script>
import MeasureApplyModal from '@/components/project/MeasureApplyModal.vue';
import StepButton from '@/components/project/stepButton.vue';
import AccordionTabList from '@/components/project/AccordionTabList.vue';
import PopupBubble from '@/components/project/PopupBubble.vue';
import ConfirmationModal from './ConfirmationModal.vue';

export default {
  components: {
    AccordionTabList,
    StepButton,
    MeasureApplyModal,
    PopupBubble,
    ConfirmationModal
  },

  props: {
    projectId: {
      type: String,
      required: true,
    },
    stepsResponse: Object,
    navigate: Function,
  },

  data() {
    return {
      disableCard: false,
      isLoading: false,
      measures: [],
      selectedMeasure: null,
      selectedRetrofit: null,
      groupedMeasures: [],
      popupVisible: {},
      popupType: {},
      dialogVisible: false,
      isDuplicate: false,
      pmIdsToDelete: [],
      quantities: {}, // Stores original quantities for retrofits
    };
  },

  created() {
    this.isLoading = true;
    this.$store.dispatch('fulfill_integrate/get_project_measures', { project_id: this.projectId })
      .then((response) => {
        this.groupedMeasures = this.sortedList(response);
        this.groupedMeasures.forEach(group => {
          group.retrofits.forEach(retrofit => {
            this.$set(this.quantities, retrofit.pm_id, retrofit.quantity);
          });
        });
      });

    this.$store.dispatch('fulfill_integrate/get_measures', { project_id: this.projectId, project_status: this.projectStatus })
      .then((response) => {
        this.measures = response;
        this.isLoading = false;
      });
  },

  computed: {
    settings() {
      return this.$store.state.partners.currentPartner.settings;
    },

    fulfillSyncDetails() {
      return this.settings.fulfill_sync_details;
    },
  },

  methods: {
    sortedList(list) {
      return list.sort((a, b) => a.sorting_tag - b.sorting_tag);
    },

    nextStep(step) {
      if (step === 'nextStep') {
        this.navigate();
      } else if (step === 'linkStep') {
        this.redirectPath(this.stepsResponse.add_btn_url);
      }
    },

    selectMeasure(measure) {
      this.selectedMeasure = measure;
      this.selectedRetrofit = measure;
      this.isDuplicate = false;
      this.$nextTick(() => {
        this.$bvModal.show('measure-apply');
      });
    },

    duplicateMeasure(measure) {
      this.selectedMeasure = measure;
      this.selectedRetrofit = measure;
      this.isDuplicate = true;
      this.$nextTick(() => {
        this.$bvModal.show('measure-apply');
      });
    },

    resetSelectedMeasures() {
      this.selectedMeasure = null;
    },

    updateMeasureList() {
      this.isLoading = true;
      this.$store.dispatch('fulfill_integrate/get_project_measures', { project_id: this.projectId})
        .then((response) => {
          this.groupedMeasures = this.sortedList(response);
          this.isLoading = false;
        });
    },
    togglePopup(retrofitId, type = 'installationDetails') {
      this.$set(this.popupVisible, retrofitId, !this.popupVisible[retrofitId]);
      this.$set(this.popupType, retrofitId, type);
    },
    getMeasureDetails(retrofitId) {
      for (const group of this.measures) {
        const measure = group.retrofits.find((retrofit) => retrofit.id === retrofitId);
        if (measure) {
          return measure.program_specific_measure_details;
        }
      }
      return null;
    },

    openDialog(retrofit) {
      if(retrofit) {
        this.pmIdsToDelete.push(retrofit.pm_id);
      }
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },

    deleteMeasure(pmIds = []) {
      this.isLoading = true;
      const payload = {
        pm_ids: pmIds.length ? pmIds : [this.selectedRetrofit.pm_id],
      };
      this.$store.dispatch('fulfill_integrate/delete_measures', { project_id: this.projectId, ...payload })
        .then(() => {
          this.dialogVisible = false;
          this.updateMeasureList();
        });
    },

    handleStepSave(step) {
      const pmIdsToDelete = [];
      const updatedRetrofits = [];

      this.groupedMeasures.forEach(group => {
        group.retrofits.forEach(retrofit => {
          if (!retrofit.quantity || retrofit.quantity <= 0) {
            pmIdsToDelete.push(retrofit.pm_id);
          } else if (retrofit.quantity !== this.quantities[retrofit.pm_id]) {
            updatedRetrofits.push(retrofit);
          }
        });
      });

      if (updatedRetrofits.length) {
        this.saveUpdatedRetrofits(updatedRetrofits, step);
      }

      if (pmIdsToDelete.length) {
        this.deleteMeasure(pmIdsToDelete);
      } else if (!updatedRetrofits.length) {
        this.nextStep(step);
      }
    },

    saveUpdatedRetrofits(retrofits, step) {
      retrofits.forEach(retrofit => {
        const data = {
          details: {
            isFieldUpdate: true,
            project_id: this.projectId,
            retrofit: retrofit,
          }
        };

        this.$store.dispatch('fulfill_integrate/post', data)
          .then(response => {
            this.$notify({
              group: 'notice',
              text: `Retrofit ${retrofit.name} updated successfully.`,
              duration: 5000,
            });
            this.$set(this.quantities, retrofit.pm_id, retrofit.quantity);
            this.nextStep(step);
          })
          .catch(error => {
            console.error(`Error updating retrofit ${retrofit.name}:`, error.message);
            this.$notify({
              group: 'notice',
              text: `Failed to update retrofit ${retrofit.name}.`,
              duration: 5000,
            });
          });
      });
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/stylesheets/components/project/measures.scss';
@import '../../assets/stylesheets/components/project/contact.scss';

.retrofit-card {
  min-height: 8rem;
  justify-content: center;
}
</style>
