<template>
<div :class="['content-box', { 'no-card': disableCard }]">
  <div class="m-2 pl-3 text-center" v-show="isLoading">
    <font-awesome-icon
      :icon="['fa', 'circle-notch']"
      class="fa-spin m-auto spinner fa-5x egx-partner-color"
    />
  </div>
  <div v-if="(offers?.length || 0) < 1" v-show="!isLoading">
    <p
      v-if="fulfill_sync_details.pages?.offers?.no_content"
      :class="fulfill_sync_details.pages.offers.no_content.class"
      :style="fulfill_sync_details.pages.offers.no_content.style"
      v-html="fulfill_sync_details.pages.offers.no_content.text"
    />
  </div>
  <div :class="['card-body', { 'no-card': disableCard }]" v-for="(offer, index) in offers" v-else>
    <div class="row">
      <div class="col-lg-8">
        <div>
          <span class="egx-partner-color">
            <h4>{{ offer.name }}</h4>
          </span>
        </div>
        <div v-html="offer.customer_description" :style="fulfill_sync_details.pages.offers.offer_card.style"/>
      </div>
      <div class="col-lg-4 d-flex flex-column justify-content-center align-items-center">
        <button class="btn btn-egx  mb-2" style="height: 3rem; width: 10rem;" @click="Apply(offer.id)">Apply</button>
      </div>
    </div>
    <hr v-if="index < offers.length - 1" />
  </div>
</div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      offers: [],
      disableCard: false,
      isLoading: false,
    };
  },

  created() {
    this.isLoading = true;
    this.$store.dispatch('fulfill_integrate/get_programs_list').then(() => {
      this.offers = this.$store.state.fulfill_integrate.programs;
      this.$emit('number-of-programs', (this.offers?.length || 0));
    }).catch((error) => {
      this.$notify({
        group: 'notice',
        text: error.message,
      });
    }).finally(() => {
      this.isLoading = false;
    });
  },
  methods: {
    Apply(offerId) {
      this.$router.push(`/applications/new?programId=${offerId}`);
    },
  },
  computed: {
    settings() {
      return this.$store.state.partners.currentPartner.settings;
    },

    fulfill_sync_details() {
      return this.settings.fulfill_sync_details;
    },
  }
}
</script>

<style scoped>
.card-body {
  margin-bottom: 10px;
}
hr {
  margin-bottom : -2rem;
}
.egx-partner-color {
  color: inherit;
}
</style>
