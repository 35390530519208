@ -0,0 +1,114 @@
<template>
  <b-modal
    id="measure-apply"
    size="xl"
    @show="showModal"
    @hide="onHide"
    @close="onHide"
    centered
    class="egx-partner-color custom-modal app"
    hide-footer
    hide-header
  >
    <div class="measure-apply">
      <h4 class="title pb-3">{{ pageSettings?.title || 'Measure Application Details' }}</h4>
      <div class="m-2 text-center" v-if="isLoading">
        <font-awesome-icon
          :icon="['fa', 'circle-notch']"
          class="fa-spin m-auto spinner fa-5x egx-partner-color"
        />
      </div>

      <div v-else>
        <ValidationObserver ref="measureSave" v-if="selectedMeasure">
          <accordion-tab-list
            id="accordion-apply-1"
            :title="pageSettings?.section_title || 'Selected Measure'"
            accordion="my-accordion-apply"
          >
            <template #body>
              <div class="d-grid pr-4 col-12">
                <h4 class="measure-title">
                  {{ measureType.name }}
                </h4>
                <h5 class="measure-sub-title">
                  {{ selectedMeasure.hierarchy_level_1 }}
                </h5>
                <div>
                  <span class="measure-description pt-2" v-html="selectedMeasure.program_specific_measure_details" />
                  <div class="container mt-4">
                    <div class="row mb-2">
                      <div class="col-md-6 align-content-center p-0">
                        Quantity (Units)
                        <span class="text-danger">*</span>
                      </div>
                      <div class="col-md-6 pl-0">
                        <validation-provider :rules="'required'" mode="lazy" v-slot="{ errors }">
                          <input
                            type="number"
                            name="quantity"
                            v-model="fieldValues['quantity']"
                            id="quantity"
                            class="question-list-item form-control"
                          />
                          <div class="msg-error text-danger" :class="errors[0] ? 'has-error' : ''">{{ errMsg = errors[0] }}</div>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </accordion-tab-list>
          <div class="mt-4" v-for="(fieldGroup, index) in fieldGroups" :key="fieldGroup.id">
            <accordion-tab-list :id="`accordion-field-${index+1}`" :title="fieldGroup.name" :accordion="`my-accordion-field-${index+1}`">
              <template #body>
                <div class="pr-4 col-12">
                  <div class="sub-section-title pt-1">
                    {{ pageSettings?.field_section_description || 'Please tell us about the installation details.' }}
                  </div>
                  <div class="container mt-4">
                    <div class="row">
                      <div class="col-md-12 col-lg-6 py-2 align-content-center" v-for="(field, index) in fieldGroup.fields" :key="field.name">
                        <div class="row mb-2">
                          <div class="col-md-6 p-0 pr-2 align-self-center">
                            <div class="m-auto field-label-parent">
                              <label :for="field.name" class="sub-section-title m-0">
                                {{  field.name !== '$' ? field.label : ' ' }}
                                <span class="text-danger" v-if="field.required === 'true'">*</span>
                              </label>
                              <template v-if="field.name !== '$' && field.inline_help !== ''">
                                    <span class="field-tooltip">
                                      <font-awesome-icon
                                        :icon="['fa', 'question-circle']"
                                        :id="`tooltip-${index}`"
                                        class="egx-partner-color ml-2"
                                      />
                                    </span>
                                <b-popover
                                  :target="`tooltip-${index}`"
                                  triggers="hover"
                                  placement="top"
                                >
                                  {{ field.inline_help }}
                                </b-popover>
                              </template>
                            </div>
                          </div>
                          <div class="col-md-6 pl-0 align-content-center">
                            <validation-provider
                              mode="lazy"
                              :rules="addValidation(field)"
                              v-slot="{ errors }"
                              :ref="'ql-item-' + field.name"
                              :custom-messages="customErrorMsg"
                            >
                              <toggle-button
                                v-model="fieldValues[field.name]"
                                class="question-list-item"
                                :name="field.name"
                                :id="field.name"
                                :width="62"
                                :height="25"
                                :font-size="15"
                                :labels="{ checked: 'Yes', unchecked: 'No' }"
                                v-if="field.type === 'BOOLEAN'"
                              />

                              <b-input-group v-else-if="field.type === 'Date'">
                                <b-form-input
                                  :id="field.name"
                                  v-model="fieldValues[field.name]"
                                  :name="field.name"
                                  type="text"
                                  class="question-list-item d-none"
                                />
                                <b-input-group-append class="w-100">
                                  <b-form-datepicker today-button reset-button close-button v-model="fieldValues[field.name]" />
                                </b-input-group-append>
                              </b-input-group>

                              <div v-else-if="field.type === 'multi_select'">
                                <multiselect
                                  v-model="fieldValues[field.name]"
                                  tag-placeholder="Add this as new option"
                                  placeholder="Select all that apply."
                                  :options="getOptions(field.rules)"
                                  :multiple="true"
                                  :taggable="true"
                                />
                                <input
                                  type="text"
                                  :name="field.name"
                                  v-model="fieldValues[field.name]"
                                  :id="field.name"
                                  class="question-list-item form-control multi-select d-none"
                                />
                              </div>
                              <div v-else-if="field.type === 'Specific values'">
                                <multiselect
                                  v-model="fieldValues[field.name]"
                                  :options="getOptions(field.rules)"
                                  :placeholder="field.required === 'true' ? getPlaceHolder(field.rules) : 'None'"
                                  :multiple="false"
                                  :taggable="false"
                                />
                                <input
                                  type="text"
                                  :name="field.name"
                                  v-model="fieldValues[field.name]"
                                  :id="field.name"
                                  class="question-list-item form-control single-select d-none"
                                />
                              </div>
                              <input
                                type="number"
                                :name="field.name"
                                v-model="fieldValues[field.name]"
                                :id="field.name"
                                class="question-list-item form-control"
                                v-else-if="field.type === 'Numeric'"
                              />
                              <textarea
                                :name="field.name"
                                v-model="fieldValues[field.name]"
                                :id="field.name"
                                class="question-list-item form-control"
                                v-else-if="field.type === 'Free-form'"
                              />
                              <input
                                type="text"
                                :name="field.name"
                                v-model="fieldValues[field.name]"
                                :id="field.name"
                                class="question-list-item form-control"
                                v-else-if="field.type === 'Free-form (single line)'"
                              />
                              <input
                                type="number"
                                :name="field.name"
                                v-model="fieldValues[field.name]"
                                :id="field.name"
                                class="question-list-item form-control"
                                v-else-if="field.type === 'Currency'"
                              />

                              <div class="msg-error text-danger" :class="errors[0] ? 'has-error' : ''">{{ errMsg = errors[0] }}</div>
                            </validation-provider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </accordion-tab-list>
          </div>
          <div class="button mt-3 text-center">
            <div @click="saveMeasure()" class="btn mr-3 btn-egx">
              Add to Application
            </div>
            <div @click="$bvModal.hide('measure-apply')" class="btn btn-egx-white">
              Cancel
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
import FieldHelper from '@/shared/mixins/field-helper';
import AccordionTabList from '@/components/project/AccordionTabList.vue';

export default {
  name: 'MeasureApplyModal',
  components: {
    Multiselect,
    AccordionTabList
  },

  mixins: [ FieldHelper ],

  props: {
    selectedMeasure: Object,
    projectId: String,
    selectedRetrofit: Object,
    fulfillSyncDetails: Object,
    isDuplicate: Boolean,
  },

  data() {
    return {
      isLoading: false,
      fieldGroups: [],
      measureType: {},
      fieldValues: {},
      customErrorMsg: {},
      errMsg: {},
      retrofit: {},
    };
  },

  computed: {
    pageSettings() {
      return this.fulfillSyncDetails?.pages?.measure?.application_details;
    }
  },

  methods: {
    showModal() {
      this.isLoading = true;

      const params = {
        project_id: this.projectId,
        measure_type_name: this.selectedMeasure.type,
      }

      this.fieldValues = {};

      if (this.selectedRetrofit) {
        this.retrofit = this.selectedRetrofit;

        this.retrofit.fields.forEach((field) => {
          if (field.type.toLowerCase() === 'numeric') {
            field.value = parseInt(field.value);
          } else if (field.type.toLowerCase() === 'currency') {
            field.value = parseFloat(field.value);
          } else if (field.type.toLowerCase() === 'boolean') {
            field.value = field.value === 'true';
          }

          this.fieldValues[field.name] = field.value;
        });
        this.fieldValues['quantity'] = this.retrofit.quantity;
      } else {
        this.retrofit = {
          type: this.selectedMeasure.type,
          program_measure_id: this.selectedMeasure.program_measure_id,
          name: this.selectedMeasure.name,
          id: this.selectedMeasure.id,
          status: 'Included',
          quantity: 0,
          fields: [],
        }
      }

      this.$store.dispatch('fulfill_integrate/get_measures_type', { ...params })
        .then((response) => {
          this.fieldGroups = response.field_groups;
          this.measureType = response.measure_type;

          if (!this.selectedRetrofit || this.isDuplicate) {
            this.retrofit['pm_id'] = response.uuid;

            this.fieldGroups.forEach((group) => {
              group.fields.forEach((field) => {
                if (field.type !== 'Spacer') {
                  this.retrofit.fields.push({
                    type: field.type,
                    object_name: field.object_name,
                    name: field.name,
                    field_group_id: field.field_group_id,
                    value: '',
                  });

                  if (field.default_value) {
                    let defaultValue = field.default_value;
                    if (field.type.toLowerCase() === 'boolean') {
                      defaultValue = field.default_value === 'true';
                    }

                    this.fieldValues[field.name] = defaultValue;
                  }
                }
              });
            });
          }
        }).catch((error) => {
          console.log(error);
        }).finally(() =>{
          this.isLoading = false;
      });
    },

    saveMeasure() {
      this.$refs.measureSave.validate()
        .then((isValid) => {
          if (isValid) {
            this.isLoading = true;

            this.retrofit.quantity = this.fieldValues.quantity;
            this.retrofit.fields.forEach((field) => {
              field.value = `${this.fieldValues[field.name]}`;
            });

            const data = {
              details: {
                isFieldUpdate: true,
                project_id: this.projectId,
                retrofit: this.retrofit,
              }
            }

            this.$store.dispatch('fulfill_integrate/post', data).then((response) => {
              this.$notify({
                group: 'notice',
                text: response.message,
                duration: 5000,
              });

            }).catch((error) => {
              console.log(error.message);
              this.$notify({
                group: 'notice',
                text: error.message,
              });
            }).finally(() => {
              this.$bvModal.hide('measure-apply');
              this.isLoading = false;
              this.$emit('on-save');
            });
          } else {
            console.log('Not Validated.');

            this.$notify({
              group: 'notice',
              text: this.fulfillSyncDetails.messages.validations,
              duration: 5000,
            });

            this.$nextTick(() => {
              const hasError = document.querySelector('.has-error');
              if (hasError) {
                hasError.scrollIntoView({ block: 'center', behavior: 'smooth' });
              }
            });
          }
        });

    },

    addValidation(question) {
      const validationList = [];

      if (question.required === 'true') {
        this.customErrorMsg['required'] = "This field is required.";
        validationList.push('required');
      }

      if (question.type === 'Numeric') {
        this.customErrorMsg['numeric'] = "This field only accepts numeric values.";
        validationList.push('numeric');
      } else if (question.type === 'Float') {
        this.customErrorMsg['decimal'] = "This field only accepts decimal values.";
        validationList.push('decimal');
      } else if (question.type === 'Currency') {
        this.customErrorMsg['double'] = "This field only accepts two decimals.";
        validationList.push('double:2');
      }

      return validationList.join('|');
    },

    onHide() {
      this.$emit('on-hide');
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/stylesheets/components/project/measures.scss';
</style>
