export default {
  header: {
    },
  stepContent: [
    {
      title: 'Step 1 of 4: Select Primary Contact',
      subtitle: '',
      description: '',
      buttons: [
        { label: 'Next', url: '/next-step?step=2' },
        { label: 'Previous', url: '/previous-step?step=1' }
      ]
    },
    {
      title: 'Step 2 of 4: Application Questions',
      subtitle: '',
      description: '',
      buttons: [
        { label: 'Next', url: '/next-step?step=3' },
        { label: 'Previous', url: '/previous-step?step=1' }
      ]
    },
    {
      title: 'Step 3 of 4: Another Step',
      subtitle: 'Subtitle for step 3.',
      buttons: [
        { label: 'Next', url: '/next-step?step=4' },
        { label: 'Previous', url: '/previous-step?step=2' }
      ]
    },
    {
      title: 'Step 4 of 4: Final Step',
      subtitle: 'Subtitle for step 4.',
      description: 'Description for step 4.',
      buttons: [
        { label: 'Next', url: '/next-step?step=4' },
        { label: 'Previous', url: '/previous-step?step=3' }
      ]
    }
  ],
  application: {
    header: {
      title: 'My Applications',
      subtitle: 'Application Overview',
      description: 'Review or update your existing applications and projects. For assistance, feel free to <a class="egx-partner-color" href="https://www.efficiencyns.ca/contact-us">contact us</a>.'
    },
    applications: [
      {
        title: 'Moderate Income Rebate Home Energy Assessment and Oil to Heat Pump affordability Grant',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Eligibility Review',
        description: 'Thank you! Your application has been received and awaiting  review by our  team. If there is an issue or missing information, someone will reach out to via email or phone.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'HomeWarming',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      },
      {
        title: 'Moderate income rebate home energy assessment',
        id: '460290',
        status: 'Project initiation',
        description: 'Your application is in progress. Please ensure you check the terms and conditions box to submit.',
        lastUpdated: '2025-01-13',
      }
    ]
  },
  offers: {
    header: {
      title: 'Available Programs',
      description: 'Apply now to find out if you qualify for rebates of financial assistance on energy-efficient heating upgrades. Select the program that best suits your needs to get started with your application.'
    },
  },
  contactHeader: {
    title: '',
    subtitle: '',
    description: ''
  },
  contacts: [
    { id: 1, name: 'John Doe' },
    { id: 2, name: 'Jane Doe' }
  ]
};