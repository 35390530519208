<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <Offers />
      </div>
    </div>
  </div>
</template>

<script>
import Offers from '../components/Offers.vue';

export default {
  components: {
    Offers,
  },
  data() {
    return {
      disableCard: false,
    };
  },
}
</script>
