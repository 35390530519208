export default {
  methods: {
    getPlaceHolder(rules) {
      const placeHolder = rules.split(';').filter((option) => {
        return option.charAt(0) === '-' && option.charAt(option.length-1) === '-';
      });

      let placeHolderValue = 'Required';

      if (placeHolder.length > 0 && placeHolder[0]) {
        placeHolderValue = placeHolder[0].substring(1, placeHolder[0].length-1).trim();
      }

      return placeHolderValue;
    },

    getOptions(rules) {
      let options = rules.replace(/(\r\n|\n|\r)/gm, '').split(';');
      options = options.filter((option) => {
        return option.charAt(0) !== '-' && option.charAt(option.length-1) !== '-' && option !== '';
      });

      return options;
    },
  },
};
