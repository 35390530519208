<template>
  <div class="prq-section col-12 py-4 pl-0">
    <div class="content-box row">
      <div class="col-12">
        <header>
          <h2 class="mt-3 title">
            <span class="egx-partner-color">
              {{ section.name }}
            </span>
          </h2>
          <p class="sub-title" v-html="section.message_or_instructions" />
        </header>
      </div>
      <template>
        <div class="container pb-4">
          <div class="header row pt-2">
            <div class="col-sm sub-section-title">
              Question or Requirement
            </div>
            <div class="col-sm sub-section-title">
              Response
            </div>
          </div>
          <div v-for="question in section.questions" :key="question.id">
            <div class="row pt-3">
              <div class="col-sm m-auto align-self-center response-title">
                <label :for="question.question" class="sub-section-title">
                  {{ question.question }}
                  <span class="text-danger" v-if="question.required === 'true'">*</span>
                </label>
              </div>
              <div class="col-sm" @focusout="emitAnswerList()">
                <validation-provider
                  mode="lazy"
                  :rules="addValidation(question)"
                  v-slot="{ errors }"
                  :ref="'ql-item-' + question.id"
                  :custom-messages="customErrorMsg"
                >
                  <toggle-button
                    v-model="responses[`${question.id}`]['response']"
                    class="question-list-item"
                    :name="question.name"
                    :id="question.id"
                    :width="62"
                    :height="25"
                    :font-size="15"
                    :labels="{ checked: 'Yes', unchecked: 'No' }"
                    v-if="question.type === 'Boolean'"
                  />

                  <b-input-group v-else-if="question.type === 'Date'">
                    <b-form-input
                      :id="`${question.id}`"
                      v-model="responses[`${question.id}`]['response']"
                      :name="question.name"
                      type="text"
                      class="question-list-item d-none"
                    />
                    <b-input-group-append class="w-100">
                      <b-form-datepicker
                        today-button
                        reset-button
                        close-button
                        v-model="responses[`${question.id}`]['response']"
                        :value="formatDate(responses[`${question.id}`]['response'])"
                      />
                    </b-input-group-append>
                  </b-input-group>

                  <div v-else-if="question.type === 'multi_select'">
                    <multiselect
                      v-model="responses[`${question.id}`]['response']"
                      tag-placeholder="Add this as new option"
                      placeholder="Select all that apply."
                      :options="getOptions(question.rules)"
                      :multiple="true"
                      :taggable="true"
                    />
                    <input
                      type="text"
                      :name="question.name"
                      v-model="responses[`${question.id}`]['response']"
                      :id="question.id"
                      class="question-list-item form-control multi-select d-none"
                    />
                  </div>
                  <div v-else-if="question.type === 'Specific values'">
                    <multiselect
                      v-model="responses[`${question.id}`]['response']"
                      :options="getOptions(question.rules)"
                      :placeholder="question.required === 'true' ? getPlaceHolder(question.rules) : 'None'"
                      :multiple="false"
                      :taggable="false"
                    />
                    <input
                      type="text"
                      :name="question.name"
                      v-model="responses[`${question.id}`]['response']"
                      :id="question.id"
                      class="question-list-item form-control single-select d-none"
                    />
                  </div>
                  <div v-else-if="question.type === 'Specific values (multi-select)'">
                    <multiselect
                      v-model="multiSelectResponses[question.id]"
                      :options="getOptions(question.rules)"
                      :placeholder="question.required === 'true' ? getPlaceHolder(question.rules) : 'None'"
                      :multiple="true"
                      :taggable="false"
                      @input="updateMultiSelectResponse(question.id)"
                      class="specific-values-multi-select"
                    />
                    <input
                      type="text"
                      :name="question.name"
                      :value="responses[`${question.id}`]['response']"
                      :id="question.id"
                      class="question-list-item form-control multi-select d-none"
                    />
                  </div>
                  <input
                    type="number"
                    :name="question.name"
                    v-model="responses[`${question.id}`]['response']"
                    :id="question.id"
                    class="question-list-item form-control"
                    v-else-if="question.type === 'Numeric'"
                  />
                  <textarea
                    :name="question.name"
                    v-model="responses[`${question.id}`]['response']"
                    :id="question.id"
                    class="question-list-item form-control"
                    v-else-if="question.type === 'Free-form'"
                  />
                  <input
                    type="text"
                    :name="question.name"
                    v-model="responses[`${question.id}`]['response']"
                    :id="question.id"
                    class="question-list-item form-control"
                    v-else-if="question.type === 'Free-form (single line)'"
                  />

                  <span class="msg-error text-danger" :class="errors[0] ? 'has-error' : ''">{{ errMsg = errors[0] }}</span>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import FieldHelper from '@/shared/mixins/field-helper';

export default {
  name: 'Prqs',

  components: {
    Multiselect,
  },

  mixins: [ FieldHelper ],

  props: {
    section: Object,
  },

  data() {
    return {
      responses: {},
      customErrorMsg: {},
      errMsg: '',
    };
  },

  created() {
    console.log(this.section.questions);
    this.section.questions.forEach((question) => {
      this.$set(this.responses, question.id, {
        id: question.id,
        type: question.type,
        response: question.response
      })
    });

    this.section.questions =
      this.section.questions.sort(
        (a, b) => a.sorting_tag.localeCompare(b.sorting_tag, 'en', { numeric: true })
      );
  },

  computed: {
    multiSelectResponses() {
      const result = {};
      Object.keys(this.responses).forEach((questionId) => {
        const response = this.responses[questionId]?.response;
        if (response && typeof response === 'string') {
          result[questionId] = response.split(';').map(item => item.trim());
        } else {
          result[questionId] = Array.isArray(response) ? response : [];
        }
      });
      return result;
    },
  },

  methods: {
    addValidation(question) {
      const validationList = [];

      if (question.required === 'true') {
        this.customErrorMsg['required'] = "This field is required.";
        validationList.push('required');
      }

      if (question.type === 'Numeric') {
        this.customErrorMsg['numeric'] = "This field only accepts numeric values.";
        validationList.push('numeric');
      } else if (question.type === 'Float') {
        this.customErrorMsg['decimal'] = "This field only accepts decimal values.";
        validationList.push('decimal');
      }

      return validationList.join('|');
    },

    emitAnswerList() {
      this.$emit('answerList', this.section.id, this.responses);
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return new Date(year, month - 1, day);
    },

    updateMultiSelectResponse(questionId) {
      const model = this.multiSelectResponses[questionId];
      this.responses[questionId].response = model.join(';');
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css" />
<style lang="scss" scoped>
@import '../../assets/stylesheets/components/project/prqs.scss';

.specific-values-multi-select {
  max-width: 29.4rem;
}
</style>
