<template>
  <div class="accordion" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button
          block
          v-b-toggle="id"
          variant="info"
          class="button-accordion btn-egx text-left"
        >
          <span class="when-closed">
            <font-awesome-icon :icon="['fa', 'chevron-up']" />
          </span>
          <span class="when-open">
            <font-awesome-icon :icon="['fa', 'chevron-down']" />
          </span>
          &emsp; {{ title }}
        </b-button>
      </b-card-header>
      <b-collapse :id="id" visible :accordion="accordion" role="tabpanel">
        <b-card-body>
          <slot name="body"></slot>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>

export default {
  name: 'AccordionTabList',

  props: {
    id: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    accordion: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  .card-header {
    background-color: var(--egx-partner-color);
  }
}

.button-accordion.btn-egx:hover, .button-accordion.btn-egx:focus {
  background-color: var(--egx-partner-color);
  border-color: var(--egx-partner-color);
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
