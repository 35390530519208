<template>
  <div class="container">
    <h1 class="d-flex justify-content-center egx-partner-color mb-3" :style="settings.style">{{ settings.title }}</h1>
    <span class="d-flex justify-content-center text-center mb-5" :style="settings.description_style">{{ settings.description }}</span>

    <form class="forgot-password-form" @submit.prevent="sendPasswordResetRequest">
      <form-field
        :formFields="settings.sub_sections.user_email.fields"
        :show="true"
        :model="credentials"
        :id-prefix="idPrefix"
      />

      <button
        class="d-flex ml-auto mr-auto mt-3 btn btn-egx-white"
        :class="settings.button.class"
        :style="settings.button.style"
      >
        {{ settings.button.text }}
      </button>
    </form>
  </div>
</template>

<script>
import FormField from '../../components/form/FormField.vue';

export default {
  name: 'ForgotPassword',

  components: {
    FormField,
  },

  data() {
    return {
      idPrefix: 'password-reset',

      credentials: {
        email: '',
      },
    };
  },

  mounted() {
    if (this.$route.query.email) {
      this.credentials.email = this.$route.query.email;
    }
  },

  computed: {
    isRequested() {
      return this.$route.name === 'request-password';
    },

    settings() {
      const forgotPasswordPage = this.$store.state.partners.currentPartner
        .settings.sections.forgot_password_page;
      return this.isRequested
        ? forgotPasswordPage.request_password
        : forgotPasswordPage.forgot_password;
    },
  },

  methods: {
    sendPasswordResetRequest() {
      const params = { email: this.credentials.email, isRequested: this.isRequested };

      this.$store.dispatch('auth/forgotPassword', params)
        .then(() => {
          this.$notify({
            group: 'notice',
            text:
              `We sent you instructions to ${this.isRequested ? 'create' : 'reset'} your password.`,
          });
        })
        .catch((error) => {
          const message = error.response.data.errors[0].title
            || 'There was an error with your request, Please try again in a few minutes';

          this.$notify({
            group: 'notice',
            text: message,
          });
        });
    },
  },
};
</script>
