<template>
  <div class="detail-section" :class="{ 'loading-size': isLoading }">
    <div class="m-2 text-center" v-if="isLoading">
      <font-awesome-icon
        :icon="['fa', 'circle-notch']"
        class="fa-spin m-auto spinner fa-5x egx-partner-color"
      />
    </div>
    <div :class="[{'content-box': !disableCard}, { 'no-card': disableCard }, 'mb-4']" v-else>
      <div class="card-wrapper">
        <div
          class="btn btn-link filter-link"
          @click="$bvModal.show('measure-filter')"
        >
          Filter by Measure Categories
        </div>
        <br />

        <div class="selected-tags-list mt-1">
          <div
            class="tags-content-box row btn btn-egx-white mr-3 ml-auto"
            v-for="item in selectedFilters"
            :key="item"
          >
            {{ item }}
            <span
              class="ml-2"
              role="button"
              @click="removeFilters(item)"
            >
              <font-awesome-icon :icon="['fa', 'times']" />
            </span>
          </div>
        </div>
        <div v-for="(groupedMeasure, index) in groupedMeasures" :key="groupedMeasure.id">
          <div class="measure-section col-12 py-4" v-show="groupFilterList.includes(groupedMeasure.id)">
            <div class="row">
              <accordion-tab-list :id="`accordion-${index+1}`" :title="groupedMeasure.name" :accordion="`my-accordion--${index+1}`">
                <template #body>
                  <div class="incentives-list">
                    <div class="mr-2" v-for="(retrofit, index) in groupedMeasure.retrofits" :key="retrofit.id">
                      <div class="retrofit-section" v-if="checkFilter(retrofit.hierarchy_level_1, groupedMeasure.id)">
                        <hr v-show="index !== 0" />
                        <div class="d-flex row">
                          <div class="d-grid pr-4 col-md-9 col-sm-12 pb-2">
                              <span class="measure-title">
                                {{ retrofit.name }}
                              </span>
                            <span class="measure-sub-title">
                                {{ retrofit.hierarchy_level_1 }}
                            </span>
                            <span class="measure-description pt-2" v-html="retrofit.program_specific_measure_details" />
                          </div>

                          <div class="button d-flex flex-column justify-content-center col-md-3 col-sm-12">
                            <div class="btn mb-1 btn-egx w-100" @click="selectMeasure(retrofit)">
                              Select Measure
                            </div>
                            <div class="btn btn-egx-white">
                              Learn More
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </accordion-tab-list>
            </div>
          </div>
        </div>
      </div>
      <step-button
        :steps-response="stepsResponse"
        @navigate="navigate"
        @save-data="nextStep"
        :data-save-required="true"
        class="mr-n4"
      />
    </div>
    <measure-filter-modal
      :selected-filters="selectedFilters"
      :filter-list="filterList"
      @update-selected-filters="updateSelectedFilters"
    />
    <measure-apply-modal
      :project-id="projectId"
      :selected-measure="selectedMeasure"
      :fulfillSyncDetails="fulfillSyncDetails"
      @on-hide="resetSelectedMeasures"
    />
  </div>
</template>

<script>
import MeasureFilterModal from '@/components/project/MeasureFilterModal.vue';
import MeasureApplyModal from '@/components/project/MeasureApplyModal.vue';
import StepButton from '@/components/project/stepButton.vue';
import AccordionTabList from '@/components/project/AccordionTabList.vue';

export default {
  components: {
    AccordionTabList,
    StepButton,
    MeasureFilterModal,
    MeasureApplyModal
  },

  props: {
    projectId: {
      type: String,
      required: true,
    },
    content: Object,
    stepsResponse: Object,
    navigate: Function,
    projectStatus: String,
    stepConfiguration: Object,
  },

  data() {
    return {
      disableCard: true,
      isLoading: false,
      groupedMeasures: [],
      selectedFilters: [],
      filterList: [],
      groupFilterList: [],
      selectedMeasure: null,
    };
  },

  created() {
    this.isLoading = true;
    this.$store.dispatch('fulfill_integrate/get_measures', { project_id: this.projectId, project_status: this.projectStatus })
      .then((response) => {
        this.groupedMeasures = this.sortedList(response);
        this.collectFilters();
        this.filterList = this.filterList.sort();
        this.isLoading = false;
      });
  },

  computed: {
    settings() {
      return this.$store.state.partners.currentPartner.settings;
    },

    fulfillSyncDetails() {
      return this.settings.fulfill_sync_details;
    },
  },

  methods: {
    removeFilters(item) {
      this.groupFilterList = [];
      this.selectedFilters = this.selectedFilters.filter((selectedItem) => { return selectedItem !== item })
    },

    updateSelectedFilters(allSelectedFilters) {
      this.groupFilterList = [];
      this.selectedFilters = allSelectedFilters;
    },

    sortedList(list) {
      return list.sort((a, b) => a.sorting_tag - b.sorting_tag);
    },

    checkFilter(value, groupId) {
      const check = (this.selectedFilters.length === 0) || this.selectedFilters.includes(value);

      if(check && this.groupFilterList.indexOf(groupId) === -1) { this.groupFilterList.push(groupId); }

      return check;
    },

    collectFilters() {
      this.groupedMeasures.forEach((groupMeasure) => {
        groupMeasure.retrofits.forEach((measure) => {
          if(this.filterList.indexOf(measure.hierarchy_level_1) === -1 && measure.hierarchy_level_1) {
            this.filterList.push(measure.hierarchy_level_1);
          }
        })
      });
    },

    nextStep(step) {
      if (step === 'nextStep') {
        this.navigate();
      } else if (step === 'linkStep') {
        this.redirectPath(this.stepsResponse.add_btn_url);
      }
    },

    selectMeasure(measure) {
      this.selectedMeasure = measure;
      this.$nextTick(() => {
        this.$bvModal.show('measure-apply');
      });
    },

    resetSelectedMeasures() {
      this.selectedMeasure = null;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/stylesheets/components/project/measures.scss';
@import '../../assets/stylesheets/components/project/contact.scss';

</style>
