// eslint-disable-next-line import/no-cycle
import ajax from '../../ajax';

export default {
  namespaced: true,

  state: {
    integrateData: {},
    projects: [],
    programs: []
  },

  mutations: {
    set(state, data) {
      state.integrateData = data;
    },

    setProjects(state, data) {
      state.projects = data;
    },

    setPrograms(state, data) {
      state.programs = data;
    }
  },

  actions: {
    get({ commit }, params) {
      return ajax.get('/api/v1/fulfill-integrate/prqs', { params: params })
        .then((response) => {
          commit('set', response.data);
        })
        .catch((errors) => { return Promise.reject(errors.response.data) });
    },

    post({ commit }, params) {
      const url = '/api/v1/fulfill-integrate/projects/0';

      return ajax.put(url, params)
        .then((response) => {
          return response.data;
        })
        .catch((error) => { return Promise.reject(error.response.data) });
    },

    async getAttachment({ commit }, params) {
      const url = `/api/v1/fulfill-integrate/attachments/${params.id}`;

      return ajax.get(url, { responseType: 'blob', ...params })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', params.fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => Promise.reject(error));
    },

    postAttachment({ commit }, params) {
      const url = '/api/v1/fulfill-integrate/attachments';

      return ajax.post(url, params,{
        headers: {
          'Content-Type': 'multipart/form-data;',
        }
      }).then((response) => {
          return response.data;
        })
      .catch((error) => { return Promise.reject(error.response.data) });
    },

    deleteAttachment({ commit }, params) {
        const url = `/api/v1/fulfill-integrate/attachments/${params.id}`;

      return ajax.delete(url, { params: params })
        .then((response) => {
            return response.data;
        })
        .catch((error) => { return Promise.reject(error.response.data) });
    },

    getPortalSteps({ commit }, params) {
      return ajax.get('/api/v1/fulfill-integrate/portal-steps', { params: params })
        .then((response) => {
          const stepDetails = response.data.data;
          // commit('setCurrentStep', {id: stepDetails.id, stepNum: stepDetails.step_num, stepRemaining: stepDetails.step_remaining});
          return stepDetails;
        })
        .catch((error) => { return Promise.reject(error.response.data) });
    },

    getPageConfiguration({ commit }, params) {
      const queryParams = [];
      if (params.task_name) queryParams.push(`task_name=${params.task_name}`);
      if (params.project_id) queryParams.push(`project_id=${params.project_id}`);
      if (params.cur_step_name) queryParams.push(`cur_step_name=${params.cur_step_name}`);
      const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';

      return ajax.get(`/api/v1/fulfill-integrate/get_page_configuration${queryString}`)
        .then((response) => {
          return response.data.data;
        })
        .catch((error) => { return Promise.reject(error.response.data) });
    },

    async createProjects({ commit }, params) {
      const url = '/api/v1/fulfill-integrate/projects';

      return ajax.post(url, params)
        .then((response) => {
          return response.data;
        })
        .catch((error) => { return Promise.reject(error.response.data) });
    },

   async getProjects({ commit }) {
      return ajax.get('/api/v1/fulfill-integrate/projects')
        .then((response) => {
          commit('setProjects', response.data.projects.projects);
          commit('setPrograms', response.data.projects.programs);
        })
        .catch((errors) => { return Promise.reject(errors.response.data) });
    },

    async listOrSelectBuilding() {
      const url = '/api/v1/fulfill-integrate/list_or_select_building';

      return ajax.get(url)
        .then((response) => {
          return response.data;
        })
        .catch((error) => { return Promise.reject(error.response.data) });
    },

    async updateProjectPrimaryContact({ commit }, params) {
      const url = '/api/v1/fulfill-integrate/update_project_contact';

      return ajax.post(url, params)
        .then((response) => {
          return response.data;
        })
        .catch((error) => { return Promise.reject(error.response.data) });
    },

    async getProjectPrimaryContact({ commit }, params) {
      const url = `/api/v1/fulfill-integrate/get_project_selected_contact?project_id=${params.project_id}`;

      return ajax.get(url)
        .then((response) => {
          return response.data;
        })
        .catch((error) => { return Promise.reject(error.response.data) });
    },

    async getProjectDetails({ commit }, params) {
      const url = `/api/v1/fulfill-integrate/projects/${params.project_id}`;

      return ajax.get(url)
        .then((response) => {
          return response.data;
        })
        .catch((error) => { return Promise.reject(error.response.data) });
    },

    async get_project_selected({ commit }, params) {
      const url = `/api/v1/fulfill-integrate/get_project_selected?project_id=${params.project_id}`;

      return ajax.get(url)
        .then((response) => {
          return response.data;
        })
        .catch((error) => { return Promise.reject(error.response.data) });
    },

    async get_measures({ commit }, params) {
      const url = `/api/v1/fulfill-integrate/measures`;

      return ajax.get(url, { params })
        .then((response) => {
          return response.data;
        })
        .catch((error) => { return Promise.reject(error.response.data) });
    },

    async get_project_measures({ commit }, params) {
      const url = `/api/v1/fulfill-integrate/measures/project-measures`;

      return ajax.get(url, { params })
        .then((response) => {
          return response.data;
        })
        .catch((error) => { return Promise.reject(error.response.data) });
    },

    async get_measures_type({ commit }, params) {
      const url = `/api/v1/fulfill-integrate/measures/type`;

      return ajax.get(url, { params })
        .then((response) => {
          return response.data;
        })
        .catch((error) => { return Promise.reject(error.response.data) });
    },

    async get_programs_list({ commit }) {
      const url = '/api/v1/fulfill-integrate/programs';

      return ajax.get(url)
        .then((response) => {
          commit('setPrograms', response.data.programs);
        })
        .catch((error) => { return Promise.reject(error.response.data) });
    },

    async delete_measures({commit}, params) {
      const url = `/api/v1/fulfill-integrate/measures/delete_measures`;

      return ajax.post(url, params)
        .then((response) => {
          return response.data;
        })
        .catch((error) => { return Promise.reject(error.response.data) });
    }
  },
};
