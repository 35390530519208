<template>
  <div :class="['content-box', { 'no-card': disableCard }]">
    <div class="m-2 pl-3 text-center" v-show="isLoading">
      <font-awesome-icon
        :icon="['fa', 'circle-notch']"
        class="fa-spin m-auto spinner fa-5x egx-partner-color"
      />
    </div>
    <div :class="['card-body', { 'no-card': disableCard }]" v-show="!isLoading">
      <span class="egx-partner-color">
        <h2 v-html="pageConfiguration.title" />
      </span>
      <div v-html="pageConfiguration.main_instructions" />
      <OfferCard class="mt-4" @number-of-programs="updateProgramsSize"/>
      <div class="d-flex justify-content-center">
        <button
          @click="redirect(fulfill_sync_details.pages?.offers?.view_button.path);"
          :class="fulfill_sync_details.pages?.offers?.view_button.class"
          :style="fulfill_sync_details.pages?.offers?.view_button.style"
          v-if="fulfill_sync_details.pages?.offers?.view_button && totalPrograms == 0"
        >
          {{ fulfill_sync_details.pages?.offers?.view_button.text }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import OfferCard from './OfferCard.vue';
import router from '../router';

export default {
  components: {
    OfferCard,
  },
  data() {
    return {
      disableCard: false,
      totalPrograms: 0,
      pageConfiguration: {},
      isLoading: false,
    };
  },

  created() {
    this.isLoading = true;
    this.$store.dispatch('fulfill_integrate/getPageConfiguration', {
      task_name: 'availableprograms',
    })
    .then((response) => {
      this.pageConfiguration = response;
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      this.isLoading = false;
    });
  },

  computed: {
    fulfill_sync_details() {
      return this.$store.state.partners.currentPartner.settings.fulfill_sync_details;
    },
  },

  methods: {
    redirect(path) {
      router.push({ path});
    },

    updateProgramsSize(size) {
      this.totalPrograms = size;
    }
  },
}
</script>
