<template>
  <b-modal
    id="measure-filter"
    :size="'lg'"
    @show="showModal"
    centered
    class="egx-partner-color custom-modal app"
    hide-footer
    hide-header
  >
    <div class="measure-filter">
      <h4 class="title egx-partner-color">Measure Categories</h4>
      <span class="sub-title">Select 1 or more categories you're interested in.</span>

      <div class="tags-list mt-3">
        <span
          class="mt-4 mr-3"
          v-for="item in filterList" :key="item"
        >
          <div
            @click="selectFilter(item)"
            class="btn mb-2 btn-incentive tag-btn filter-options"
            :class="allSelectedFilters.includes(item) ? 'btn-egx' : 'btn-egx-white'"
          >
            {{ item }}
          </div>
        </span>
      </div>

      <div class="button mt-3 float-right">
        <div @click="$bvModal.hide('measure-filter')" class="btn btn-egx-white px-4">
          Cancel
        </div>
        <div @click="saveMeasureFilter()" class="btn ml-3 btn-egx btn-incentive px-4">
          Filter
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'MeasureFilterModal',

  props: {
    filterList: {
      Array,
      required: true,
    },
    selectedFilters: {
      Array,
      required: true,
    }
  },

  data() {
    return {
      allSelectedFilters: [],
    };
  },

  computed: {
  },

  methods: {
    showModal() {
      this.allSelectedFilters = [ ...this.selectedFilters ];
    },

    selectFilter(filter) {
      if (this.allSelectedFilters.includes(filter)) {
        this.allSelectedFilters.splice(this.allSelectedFilters.indexOf(filter), 1);
      } else {
        this.allSelectedFilters.push(filter);
      }
    },

    saveMeasureFilter() {
      this.$emit('update-selected-filters', this.allSelectedFilters);
      this.$bvModal.hide('measure-filter');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/stylesheets/components/project/measures.scss';
</style>
