<template>
  <form class="sign-up-form">
    <div class="input-group-lg">
      <ValidationObserver
        tag="form"
        @submit.prevent="updateUser()"
        ref="userEdit"
      >
        <span
          class="form-control readonly-input mb-3 d-flex"
          style="height: 45px; align-items: center;"
          id="user-email"
          v-if="!fulfill_sync_details && !detailVerificationStep"
        >
          {{ currentUser.email }}
        </span>

        <form-field
          :formFields="fields"
          :show="true"
          :model="data.updateUser"
          :id-prefix="idPrefix"
          :isFulFillSyncAllowed="fulfill_sync_details?.allowed"
        />

        <div class="form-check col-12" v-if="allowConsentField">
          <input
            class="form-check-input" type="checkbox"
            v-model="data.updateUser.consent"
            :class="settings.consent.class"
            id="consent"
            :disabled="disable_edit_profile && !detailVerificationStep"
          >
          <label
            class="form-check-label"
            for="consent"
            v-html="settings.consent.html_content"
          />
        </div>

        <div class="form-check col-12" v-if="allowMarketingField">
          <input
            class="form-check-input" type="checkbox"
            v-model="data.updateUser.subscribed"
            :class="settings.marketing.class"
            id="user-subscribed"
            :disabled="disable_edit_profile && !detailVerificationStep"
          >
          <label class="form-check-label" for="user-subscribed">
            {{ settings.marketing.text }}
          </label>
        </div>

        <button
          type="submit"
          class="btn btn-lg d-flex ml-auto mr-auto mt-3 mb-3"
          :class="settings.attributes.button3.class"
          v-if="detailVerificationStep"
        >
          {{ settings.attributes.button3.text }}
        </button>

        <button
          type="submit"
          class="btn btn-lg d-flex ml-auto mr-auto mt-3 mb-3"
          :class="settings.attributes.button.class"
          v-else-if="!disable_edit_profile"
        >
          {{ settings.attributes.button.text }}
        </button>

        <button
          type="button"
          class="btn btn-lg d-flex ml-auto mr-auto mt-3 mb-3"
          :class="settings.attributes.button2.class"
          @click="redirectBack(settings.attributes.button2['path-name'])"
          v-else
        >
          {{ settings.attributes.button2.text }}
        </button>
      </ValidationObserver>
    </div>
  </form>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import FormField from './form/FormField.vue';
import router from '../router/index';

export default {
  name: 'UserFormFields',

  components: {
    FormField,
    ValidationObserver,
  },

  props: {
    isFulFillSync: Boolean,
    detailVerificationStep: Boolean,
    conditionalDisplay: Boolean,
    conditionalDisplay2: Boolean,
  },

  data() {
    return {
      idPrefix: 'user-edit-page',
      showSuccessAlert: false,
      showErrorAlert: false,
      errorAlertMsg: '',
      sitesFields: [],
      data: {
        updateUser: {

        },
      },
    };
  },

  mounted() {
    this.updateUserData();
    if (this.fulfill_sync_details && this.fulfill_sync_details.allowed) {
      this.getSiteFields();
    }
  },

  computed: {
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    currentUser() {
      return this.$store.state.users.currentUser;
    },

    settings() {
      return this.partner.settings.sections.user_edit_page;
    },
    fulfill_sync_details() {
      return this.partner.settings.fulfill_sync_details;
    },
    disable_edit_profile() {
      return this.partner.settings.disable_user_profile_edition || this.isFulFillSync;
    },

    fields() {
      let fields = this.settings.fields;
      if (this.disable_edit_profile && !this.detailVerificationStep) {
        fields = fields.map((field) => ({
          ...field,
          disabled: 'true',
        }));
      }

      fields = fields.filter((field) => (
        (!field.conditional_display || (this.conditionalDisplay === true && field.conditional_display === true)) &&
        (!field.conditional_display2 || (this.conditionalDisplay2 === true && field.conditional_display2 === true))
      ));

      return fields;
    },

    allowConsentField() {
      return !this.data.updateUser.consent &&
        this.settings.consent && this.settings.consent?.show !== false &&
          this.settings.consent?.html_content;
    },

    allowMarketingField() {
      return this.settings.marketing && this.settings.marketing?.show !== false;
    },
  },

  methods: {
    updateUser() {
      let userdata = this.data.updateUser;
      if (this.disable_edit_profile && !this.detailVerificationStep) {
        userdata = {
          subscribed: this.data.updateUser.subscribed,
          extra_info: this.data.updateUser.extra_info,
        };
      }

      if (this.allowConsentField) {
        this.$notify({
          group: 'notice',
          text: 'You must agree to the terms and conditions to proceed.',
        });
      } else {
        this.$refs.userEdit.validate()
          .then((isValid) => {
            if (isValid) {
              this.$emit('loadingScreen', true);
              this.$store.dispatch('users/put', userdata)
                .then(() => {
                  if (this.detailVerificationStep) {
                    router.push({ name: 'fetch-account-details' });
                  } else {
                    this.$notify({
                      group: 'notice',
                      text: 'Profile updated.',
                    });
                    this.$emit('update-success');
                  }
                })
                .catch((errors) => {
                  let message = errors?.response?.data?.errors?.[0]?.title;
                  if (message) {
                    message = `Reason: ${message}`
                  }

                  console.log(message);
                  this.$notify({
                    group: 'notice',
                    text: `Unable to proceed. Please try again. ${message}`,
                  });
                }).finally(() => {
                  this.$emit('loadingScreen', false);
                });

            } else {
              this.$notify({
                group: 'notice',
                text: 'Fill in all required fields.',
              });
            }
          });
      }
    },

    updateUserData() {
      this.data.updateUser = {
        ...this.$store.state.users.currentUser,
        ...this.$store.state.users.currentUser.extra_info,
      };

      if (this.fulfill_sync_details?.allowed && ['new', 'user'].includes(this.data.updateUser.first_name.toLowerCase())) {
        this.data.updateUser.name = '';
        this.data.updateUser.first_name = '';
        this.data.updateUser.last_name = '';
      }
    },

    getSiteFields() {
      return this.$store.dispatch('users/fetchSiteFields').then((response) => {
        this.sitesFields = response.data;
      });
    },
    redirectBack(name) {
      router.push({ name });
    },
  },
};
</script>

<style scoped>
.readonly-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
</style>
