<template>
  <div :class="['content-box', { 'no-card': disableCard }]">
    <div class="m-2 pl-3 text-center" v-show="isLoading">
      <font-awesome-icon
        :icon="['fa', 'circle-notch']"
        class="fa-spin m-auto spinner fa-5x egx-partner-color"
      />
    </div>
    <div :class="['card-body', { 'no-card': disableCard }]" v-show="!isLoading">
      <span class="egx-partner-color">
        <h2 v-html="pageConfiguration.title" />
      </span>
      <div v-html="pageConfiguration.main_instructions" />
      <applicationCard class="mt-4" @number-of-applications="updateApplicationSize"/>
      <div
        class="text-center"
        v-if="isApplyButtonVisible"
      >
        <a
          :class="applications_details.view_button.class"
          :style="applications_details.view_button.style"
          :href="applications_details.view_button.path"
          :target="applications_details.view_button.open_in_new_tab ? '_blank' : '_self'"
        >
          {{ applications_details.view_button.text }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import applicationCard from './ApplicationCard.vue';

export default {
  components: {
    applicationCard,
  },
  data() {
    return {
      disableCard: false,
      totalApplications: null,
      pageConfiguration: {},
      isLoading: false,
    };
  },

  created() {
    this.isLoading = true;
    this.$store.dispatch('fulfill_integrate/getPageConfiguration', {
      task_name: 'customerhome',
    })
    .then((response) => {
      this.pageConfiguration = response;
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      this.isLoading = false;
    });
  },

  computed: {
    fulfill_sync_details() {
      return this.$store.state.partners.currentPartner.settings.fulfill_sync_details;
    },

    applications_details() {
      return this.fulfill_sync_details.pages?.applications;
    },

    isApplyButtonVisible() {
      return this.applications_details?.view_button
        && (!this.applications_details?.view_button?.visible_only_when_no_content
          || (this.applications_details?.view_button?.visible_only_when_no_content
            && this.totalApplications === 0))
        && this.applications_details?.view_button?.visible
    },
  },

  methods: {
    updateApplicationSize(size) {
      this.totalApplications = size;
    }
  }
}
</script>
